<template>
  <div>
    <stripe-checkout
      ref="checkoutRef"
      mode="payment"
      :pk="publishableKey"
      :lineItems="lineItems"
      :success-url="successURL"
      :cancel-url="cancelURL"
      @loading="v => loading = v"
    />
  </div>
</template>

<script>
import { StripeCheckout } from '@vue-stripe/vue-stripe';
export default {
  props: ["amount"],
    components: {
        StripeCheckout
    },
    data(){
        this.publishableKey = "pk_test_riRD4OckA8BPGrCyDE9K1y3n00TYNxNWSM";
        return {
          loading: false,
            lineItems: [
                {
                  price: 'price_1LTm1uH41N1VLPeQS7Ir8FVW',
                  quantity: 1,
                },
            ],
            successURL: 'http://localhost:8080',
            cancelURL: 'http://localhost:8080',
            // amountList: {
            //   10: "price_1LTm1uH41N1VLPeQS7Ir8FVW",
            //   15: "price_1LTl7lH41N1VLPeQwfngNCrO",
            //   20: "price_1LTm2WH41N1VLPeQAqZKGXns",
            //   30: "price_1LTm31H41N1VLPeQXnabUneM",
            //   35: "price_1LTm9CH41N1VLPeQN1mOvpAt",
            //   40: "price_1LTm3VH41N1VLPeQTFmYlAqN",
            //   50: "price_1LTm3zH41N1VLPeQGb2uOPbN"
            // }
        }
    },
    // created(){
    //   this.lineItems = [
    //     {
    //       price: this.amountList[this.$route.query.amount],
    //       quantity: 1
    //     }
    //   ];
      
      
    // },
    mounted(){
      this.$refs.checkoutRef.redirectToCheckout();
    },
};
</script>